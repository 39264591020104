/* Scrollbar styles */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.page-container {
  --border-clr: #cfd8dc;
  --row-height: 80px;
  --scrollbar-width: 10px;
  display: grid;
  gap: 3rem;
}

.container {
  padding-inline-start: 0.7em;
  border: 1px solid var(--border-clr);
  /* max-width: 900px; */
}

.table-wrapper {
  text-align: left;
}

.table-hero {
  display: flex;
  justify-content: space-between;
  align-items: center;

  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr max-content;
  /* grid-template-columns: ; */
  padding-inline-end: 1rem;
}

.btn-control-group {
  justify-self: end;
  display: flex;
  gap: 1rem;
}

.table-header {
  display: none;
  color: black;
  border-bottom: 1px solid var(--border-clr);

  * > .table-cell:last-child {
    margin-right: var(--scrollbar-width);
  }
}

.filter-container {
  grid-column: 1/-1;
  grid-row: 2;
  /* justify-self: start; */
  display: flex;
  gap: 0.5em;
}

.table-body {
  display: grid;
  /* TODO: set dynamic via style */
  height: calc(80px * 9);
  overflow-x: scroll;
  overflow-y: scroll;
  align-content: start;
  /* max-height: 500px; */
}

.table-body-empty-message {
  justify-self: center;
  align-self: center;
}

.table-body:has(.table-body-empty-message) {
  place-content: center;
}

.table-row {
  width: 100%;
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
  /* grid-template-columns: 250px 150px 100px 100px auto; */
  grid-template-columns: 2.5fr 1.5fr 1fr 1fr auto;

  gap: 1rem;

  &:not([data-type='row-header']) {
    /* border-bottom: 1px solid var(--border-clr); */
    height: var(--row-height);

    &:hover {
      background-color: rgb(0 0 0 / 0.1);
    }
  }
}

.table-cell {
  display: flex;
  align-items: center;
  /* justify-content: center; */

  &[data-type='expand'] {
    display: flex;
    flex-direction: row;
  }

  &:first-of-type {
    text-align: left;
  }
}

.metadata-container {
  background-color: #edeff0;
  padding-block: 1em;

  display: grid;
  padding-inline: 1rem;

  & > h3 {
    margin: 0;
    font-size: 1.125rem;
  }

  & > button {
    justify-self: end;
    align-self: end;
  }
}

.collapsible-metadata {
  background-color: #edeff0;
  display: grid;

  & > button {
    justify-self: end;
    margin-top: 0;
    margin-right: 1rem;
  }
}

.metadata-wrapper {
  display: grid;
  gap: 0.5rem;
  grid-template-columns: repeat(3, auto);
}

.table-footer {
  border-top: 1px solid var(--border-clr);
  padding-block: 1rem;
  display: grid;
  /* justify-content: end; */

  & .navigation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    padding-inline-end: 0.7em;
  }
}

.patient-class-navbar {
  display: grid;
  gap: 1rem;
  background-color: #f5f5f5;
}

.class-list {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: repeat(auto-fit, auto);
  gap: 1rem;

  justify-content: space-between;

  overflow-x: auto;

  & > * {
    /* border: 1px solid green; */
  }
}

.heading-color {
  color: rgb(0 0 0 / 0.6);
}

.class-item {
  text-align: 'center' !important;
}

.nav-section {
  display: grid;
  gap: 1rem;
  text-align: left;
  justify-content: start;
  padding-inline: 2em;

  &:first-of-type {
    padding-top: 2rem;
  }
}

.border-radius-1 {
  border-radius: 5px;
}
.center-content {
  display: grid;
  /* justify-content: center; */
}

@media (min-width: 700px) {
  .class-list {
  }
}

@media (min-width: 1120px) {
  .page-container {
    justify-self: center;
    /* align-self: center; */
    grid-template-columns: minmax(175px, 350px) 1fr;
    gap: 1rem;
    grid-auto-flow: column;
    /* border: 1px solid var(--border-clr); */
  }

  .container {
    border: none;
  }

  .patient-class-navbar {
    display: flex;
    flex-direction: column;
    padding-block: 1em;
    padding-inline: 1em;

    border-right: 1px solid var(--border-clr);
  }

  .nav-section {
    display: block;
    /* flex-direction: column; */
  }

  .table-header {
    display: block;
  }

  .class-list {
    overflow-x: hidden;
    grid-template-columns: 1fr;

    gap: 0;
    grid-auto-flow: row;
    overflow-y: scroll;
    max-height: 600px;
    justify-content: stretch;
  }

  .class-list > * > * {
    padding-block: 0.5em;
  }

  .filter-container {
    grid-column: 1/-1;
  }

  .class-item {
    /* background-color: red; */
  }
}
