ul {
  list-style: none;
}

.checkbox {
  font-size: 16px;
  user-select: none;
  min-height: 320px;
  padding: 20px;
  box-sizing: content-box;
}

.checkbox .tree,
.checkbox .tree-node,
.checkbox .tree-node-group {
  list-style: none;
  margin: 0;
  padding: 0;
}

.checkbox .tree-branch-wrapper,
.checkbox .tree-node__leaf {
  outline: none;
}

.checkbox .tree-node {
  cursor: pointer;
}

.checkbox .tree-node .name:hover {
  background: rgba(0, 0, 0, 0.1);
}

.checkbox .tree-node--focused .name {
  background: rgba(0, 0, 0, 0.2);
}

.checkbox .tree-node {
  display: inline-block;
}

.checkbox .checkbox-icon {
  margin: 0 5px;
  vertical-align: middle;
}

.checkbox button {
  border: none;
  background: transparent;
  cursor: pointer;
}

.checkbox .arrow {
  margin-left: 5px;
  vertical-align: middle;
}

.checkbox .arrow--open {
  transform: rotate(90deg);
}
